var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.state.loaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1):_c('v-container',[_c('div',{staticClass:"o-message-list"},[_c('v-data-table',{attrs:{"items":_vm.state.items,"headers":_vm.state.headers,"options":_vm.state.options,"server-items-length":_vm.state.total,"loading":_vm.state.loading,"footer-props":{ itemsPerPageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000] }},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s((_vm.state.options.page - 1) * _vm.state.options.itemsPerPage + _vm.state.items.indexOf(item) + 1))]}},{key:"item.attachments",fn:function(ref){
var item = ref.item;
return [(item.attachments && item.attachments.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-paperclip")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.messageWithAttachment')))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'panel.event.view.alertModule.edit', params: { mtid: item.id } }}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.edit')))])]),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":""},on:{"click":function($event){return _vm.copyItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-content-duplicate")])],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])])]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('layout.misc.areYouSure')))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(_vm._s(_vm.$t('layout.misc.cancel')))]),_c('v-btn',{attrs:{"depressed":"","dark":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('layout.misc.delete')))])],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, tooltip, dialog)),[_c('v-icon',[_vm._v("mdi-email-arrow-right-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('layout.misc.testEmail.sendTestEmail')))])])]}}],null,true),model:{value:(item.dialog),callback:function ($$v) {_vm.$set(item, "dialog", $$v)},expression:"item.dialog"}},[_c('m-test-email',{attrs:{"messageId":item.id},on:{"close":function($event){item.dialog = false}}})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }