




















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MTestEmail: () => import("@/components/molecules/m-test-email.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      headers: [
        { value: "index", text: root.$tc("layout.misc.orderNumber"), width: 1 },
        {
          value: "title",
          text: root.$tc("panel.event.abstract.config.mails.messageTitle"),
        },
        { value: "attachments", text: "", align: "right", width: 1 },
        { text: "", value: "actions", align: "right", width: 200 },
      ],
      empty: false,
      items: [],
      table: false,
      loading: false,
      loaded: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 50,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`message-template/${root.$route.params.id}/other`, {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
          },
        })
        .then(({ data: { messages, total } }) => {
          state.empty = false;
          state.items = messages;
          state.total = total;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    const deleteItem = (item: { id: string; deleteDialog: boolean }) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .delete(`message-template/other/${item.id}`, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          item.deleteDialog = false;
          fetchData();
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);
    watch(() => state.options, fetchData, { deep: true });

    const copyItem = (id: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`message-template/other/${id}`)
        .then(({ data }) => {
          state.loading = true;

          const title = prompt(
            root.$tc("panel.event.alerts.enterNewTitle"),
            `${data.title} (kopia)`
          );

          if (title) {
            axiosInstance
              .post(
                `message-template/${root.$route.params.id}/other`,
                {
                  template: data.template || undefined,
                  design: data.design || undefined,
                  name: title || undefined,
                  title: title || undefined,
                  attachCertificate: data.attachCertificate || false,
                  attachTextToPdfMessage: data.attachTextToPdfMessage || false,
                  attachments:
                    data.attachments.map((item: any) => ({
                      filename: item.filename,
                      content: Buffer.from(
                        item.content.data,
                        "base64"
                      ).toString("ascii"),
                    })) || [],
                },
                {
                  headers: {
                    event: root.$route.params.id,
                  },
                }
              )
              .then(() => {
                root.$store.commit("snackbar/PUSH_MESSAGE", {
                  id: uuid(),
                  color: "primary",
                  message: root.$tc(
                    "panel.event.abstract.config.mails.copyMessageSuccess"
                  ),
                });
                fetchData();
              })
              .catch((error) => {
                console.log(error);
                alert(root.$tc("panel.event.alerts.copyError"));
              })
              .finally(() => {
                state.loading = false;
              });
          } else {
            state.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          state.loading = false;
        });
    };

    return { state, fetchData, deleteItem, copyItem };
  },
});
